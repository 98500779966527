import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import './scss/main.scss';

import Login from "./component/login/Login";
import Home from "./component/home/home";
import Auth from "./component/login/Auth";

export default class App extends Component {

	componentDidMount() {
		document.title = '107th.throughtheinferno.com';
	}

	render() {
		return (
			<Router>
					<div className="App">
						<Route exact path="/" component={Login} />
						<Route exact path="/auth" component={Auth} />
						<Route path="/home" component={Home}/>
					</div>
			</Router>
		);
	}
}