import React, {Component} from 'react';
import Chart from 'chart.js';

export default class ChartAreaChart extends React.Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
	}

	componentDidUpdate() {
		//this.myChart.data.labels = this.props.data.map(d => d.label);
		//this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
		this.myChart.update();
	}

	componentDidMount() {
		this.myChart = new Chart(this.canvasRef.current, {
			type: 'line',
			options: {
				maintainAspectRatio: false,
				legend: {
					position: "right",
					align: "left"
				},
				plugins: {
					filler: {
						propagate: true
					}
				},
				scales: {
					xAxes: [{
						id: 'xAxis',
						type: 'category',
						labels: this.props.labels,
						scaleLabel: {
							display: true,
							labelString: 'Year and Month',
							ticks: {
								stepSize: 1
							}
						}
					}],
					yAxes: [{
						id: 'yAxis',
						type: 'linear',
						stacked: true,
						scaleLabel: {
							display: true,
							labelString: 'Number of Ban'
						},
						ticks: {
							beginAtZero: true
						}
					}]
				}
			},
			data: {
				datasets: this.props.data
			}
		});

		this.myChart.update();
	}


	render() {
		return(
			<div className="ChartArea">
				<canvas ref={this.canvasRef} />
			</div>
		);
	}
}