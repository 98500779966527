export const getPlaneName = (plane) => {
	let planeTxt = "";
	switch (plane) {
		case "a10c":
			planeTxt = "A-10C";
			break;
		case "m2000":
			planeTxt = "M-2000C";
			break;
		case "av8b":
			planeTxt = "AV-8B N/A";
			break;
		case "f18":
			planeTxt = "F/A-18C";
			break;
		case "f14a":
			planeTxt = "F-14A";
			break;
		case "f14b":
			planeTxt = "F-14B";
			break;
		case "f16":
			planeTxt = "F-16C";
			break;
		case "f15c":
			planeTxt = "F-15C";
			break;
		case "jf17":
			planeTxt = "JF-17";
			break;
		case "mig29a":
			planeTxt = "MiG-29A";
			break;
		case "mig29s":
			planeTxt = "MiG-29S";
			break;
		case "mig29g":
			planeTxt = "MiG-29G";
			break;
		case "su33":
			planeTxt = "Su-33";
			break;
		case "j11":
			planeTxt = "J-11";
			break;
		case "su27":
			planeTxt = "Su-27";
			break;
		case "su25":
			planeTxt = "Su-25";
			break;
		case "su25T":
			planeTxt = "Su-25T";
			break;
		case "a10a":
			planeTxt = "A-10A";
			break;
		case "p51":
			planeTxt = "P-51D";
			break;
		case "tf51":
			planeTxt = "TF-51D";
			break;
		case "fw190d9":
			planeTxt = "Fw 190 D-9";
			break;
		case "bf109k4":
			planeTxt = "Bf 109 K-4";
			break;
		case "spitfirelfmkix":
			planeTxt = "Spitfire LF Mk IX";
			break;
		case "spitfirelfmkixCW":
			planeTxt = "Spitfire LF Mk IX CW";
			break;
		case "fw190a8":
			planeTxt = "Fw 190 A-8";
			break;
		case "i16":
			planeTxt = "I-16";
			break;
		case "ka50":
			planeTxt = "Ka-50";
			break;
		case "uh1h":
			planeTxt = "UH-1H";
			break;
		case "mi8":
			planeTxt = "MI-8MTV2";
			break;
		case "gazelleM":
			planeTxt = "SA342M";
			break;
		case "gazelleL":
			planeTxt = "SA342L";
			break;
		case "gazelleMistral":
			planeTxt = "SA342 Mistral";
			break;
		case "gazelleMinigun":
			planeTxt = "SA342 Minigun";
			break;
		case "f5e":
			planeTxt = "F-5E";
			break;
		case "ajs37":
			planeTxt = "AJS-37";
			break;
		case "mig21":
			planeTxt = "MiG-21bis";
			break;
		case "mig15":
			planeTxt = "MiG-15bis";
			break;
		case "f86f":
			planeTxt = "F-86F";
			break;
		case "mig19":
			planeTxt = "MiG-19P";
			break;
		case "l39c":
			planeTxt = "L-39C";
			break;
		case "l39za":
			planeTxt = "L-39ZA";
			break;
		case "c101cc":
			planeTxt = "C-101CC";
			break;
		case "c101eb":
			planeTxt = "C-101EB";
			break;
		case "yak52":
			planeTxt = "Yak-52";
			break;
		case "ce2":
			planeTxt = "Christen Eagle II";
			break;
		case "atc":
			planeTxt = "ATC";
			break;
		case "awacs":
			planeTxt = "AWACS";
			break;
		case "fac":
			planeTxt = "FAC";
			break;
		case "lso":
			planeTxt = "LSO";
			break;
		default:
			planeTxt += "Unknown Role";
			console.warn("Unkown Role:", plane);
			break;
	}

	return planeTxt;
};

export const getEventMapName = (inMap) => {
	let map = "";

	switch (inMap) {
		case "pg":
			map = "Persian Gulf";
			break;

		case "ccs":
			map = "Caucasus";
			break;

		case "nttr":
			map = "Nevada";
			break;

		case "normandy":
			map = "Normandy";
			break;

		default:
			map = "Undecided";
			break;
	}

	return map;
};

export const allPlaneList = {
	a10c: false,
	m2000: false,
	av8b: false,
	f18: false,
	f14a: false,
	f14b: false,
	mig29a: false,
	mig29s: false,
	mig29g: false,
	su33: false,
	su25: false,
	su25T: false,
	su27: false,
	a10a: false,
	f15c: false,
	p51: false,
	tf51: false,
	fw190d9: false,
	fw190a8: false,
	bf109k4: false,
	spitfirelfmkix: false,
	spitfirelfmkixCW: false,
	i16: false,
	ka50: false,
	mi8: false,
	uh1h: false,
	gazelleM: false,
	gazelleL: false,
	gazelleMistral: false,
	gazelleMinigun: false,
	f5e: false,
	ajs37: false,
	mig21: false,
	mig15: false,
	f86f: false,
	mig19: false,
	l39c: false,
	l39za: false,
	c101cc: false,
	c101eb: false,
	yak52: false,
	ce2: false,
	f16: false,
	jf17: false,
	j11: false,
	atc: false,
	awacs: false,
	fac: false,
	lso: false
};

export const formatDate = (dateInput) => {
	const date = new Date(dateInput);
	const options = {
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		hour: 'numeric',
		minute: 'numeric',
		hour12: false
	};
	const dateTimeFormat = new Intl.DateTimeFormat('en', options);
	const [{value: month}, , {value: day}, , {value: year}, , {value: hour}, , {value: minute}] = dateTimeFormat.formatToParts(date);

	return year.toString() + " " + month.toString() + " " + day.toString()+ " " + hour.toString() + ":" + minute.toString();
};

export const generateColors = (numberOfColors) =>{
	let colorTab = [];

	const colorConverter = (number) => {
		let output = "";

		if (number >= 0 && number <= 9) {
			output += number.toString();
		} else {
			switch (number) {
				case 10:
					output += "a";
					break;
				case 11:
					output += "b";
					break;
				case 12:
					output += "c";
					break;
				case 13:
					output += "d";
					break;
				case 14:
					output += "e";
					break;
				case 15:
					output += "f";
					break;
			}
		}
		return output;
	};

	for (let i = 0; i < numberOfColors; i++){
		let singleColor = "#";

		for(let j = 0; j < 6; j++){
			let number = Math.round(Math.random() * 16);
			singleColor += colorConverter(number);
		}

		colorTab.push(singleColor);
	}

	return colorTab;
};

const defaultExport = "default export from comon.js";
export default defaultExport;