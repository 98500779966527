import React, {Component} from 'react';

export default class HomePage extends Component {
	render(){
		return(
			<div className="homePageContainer">
				<div className="homePage">
					<h1>Welcome to the 107th Joint Aviation Squadron</h1>
					<p>We are looking for very active community members who uphold the highest standards of Through The Inferno and go above and beyond the standards set forth within our community standards. In
					   short,
					   we expect a 107th prospect to be extremely active on both the server, and discord, insanely friendly within the community, and a person who other community players both instantly recognize,
					   and
					   highly respect.</p>
					<p>In contrary to a regular squadron that might focus on a couple of aircraft/platform and run a catered mission for those, Through The Inferno provides us with a dynamic battleground and
					   environment. Randomized targets, moving assets and AI mixed with real life weather just to name a few. All this combined with human AWACS / ATC / FAC services enable us to offer the most
					   wholistic
					   PvE experience out there, using everything that DCS has to offer.</p>
					<div><span>As a way to try to describe what being a 107th pilot is, here is a list what a 107th pilot is not:</span>
						<ul>
							<li>Not being interested in flying and helping other members of 107th</li>
							<li>Endangering and impairing other pilots’ experiences by lack of communication or collaboration.</li>
							<li>Only interested in how many kills you can get, regardless of the tactical situation.</li>
							<li>Disrespectful with others and incapable of having mature discussions.</li>
						</ul>
					</div>
					<p>Our goal is to offer an advanced online collaborative PvE experience for our players, and as a member, you are expected to uphold that by overcoming dynamic and challenging scenarios, we
					   achieve
					   this by working collaboratively with each other. A simple way to put it is to say that a 107th pilot is someone that is interested in more than just flying an aircraft but also interested in
					   how
					   it’s done in real life.</p>
					<p>Once someone becomes a 107th-Recruit, they are expected to finish a basic airmanship qualification within an allotted time, this lets us know that the player understands some basic concepts
					   like
					   landing patterns, navigation and formations in order to safely fly with the squadron. After a basic qualification is completed, a recruit will need to complete the airframe qualification they
					   applied for before he or she becomes a full member.</p>
				</div>
				<div className="disclaimer">
					<h1>Website Disclaimer</h1>

					<h2>Consent</h2>
					<p>By using <a href='https://107th.throughtheinferno.com'>107th.throughtheinferno.com</a>, you hereby consent to our disclaimer and agree to its terms.</p>
					<p>107th.throughtheinferno.com will not be liable for any damages experienced in connection with the use of our website.</p>
					<p><strong>If you do not agree to our Disclaimer STOP now and do not access or use this website.</strong></p>

					<h2>Not Legal or Financial Advice</h2>
					<p>The authors of 107th.throughtheinferno.com are not legal or financial advisors and therefore the information found on this website is not intended to be used in place of professional legal
					   and/or financial advice.</p>
					<p>We have taken care to ensure the information contained on this website is correct however, we are not responsible for any errors or omissions.</p>

					<h2>External Links </h2>
					<p>Some links on this website connect to external websites which are maintained by third parties. 107th.throughtheinferno.com is not responsible for any of the information provided by a third
					   party website. </p>
					<p>You are subject to the terms and conditions, disclaimer, and privacy policy of the third party websites when you leave our website.</p>

					<h2>Personal Responsibility</h2>
					<p>You acknowledge you are using our website voluntarily and that any choices, actions and results now and in the future are solely your responsibility.</p>
					<p>107th.throughtheinferno.com will not be liable to you or any other party for any decision made or action taken in reliance on the information given by our service.</p>
				</div>
			</div>
		)
	}
}