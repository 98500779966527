import React, {Component} from 'react';
import axios from "axios";
import SingleSlot from "./SingleSlot";
import {Redirect} from "react-router-dom";
import {avatarPlaceholder} from "../image";
import {getPlaneName, getEventMapName, allPlaneList, formatDate} from "../comon";
import Countdown from "./Countdown";

//--------------------------------------------------------------------------------------------------------------------------------------------------- Single Event
export default class SingleEvent extends Component {

	state = {};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	constructor(props) {
		super(props);

		this.state = {
			barIsShowing: false,
			userIsParticipant: false,
			eventId: this.props.eventId,
			participant: props.event.participant
		};

	}

	componentDidMount() {
		console.log(this.props.event.eventVisibility);
		this.checkIfUserIsParticipant();
	}

	canEditEvent = () => {
		if (this.props.canCreateEvent) {
			return <span className="contextMenu" onClick={this.toggleAdminBar}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"/></svg></span>;
		}
	};

	toggleAdminBar = () => {
		if (this.state.barIsShowing) {
			this.setState({barIsShowing: false});
		} else {
			this.setState({barIsShowing: true});
		}
	};

	singleEventRefresh = () => {
		this.server.post("/getEvent", null, this.requestAuth)
			.then((response) => {

				for (let event of response.data) {
					if (event.id === this.state.eventId) {
						this.setState({
							participant: event.content.participant
						});

						this.checkIfUserIsParticipant();
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	displaySlotList = () => {
		let out = [];

		if (this.props.event.allPlaneAvailable) {
			for (let [plane] of Object.entries(allPlaneList)) {
				out.push(<SingleSlot plane={plane} key={plane} eventId={this.props.eventId} refresh={this.singleEventRefresh}/>);
			}
		} else {
			for (let [plane] of Object.entries(this.props.event.eventPlane)) {
				if (this.props.event.eventPlane[plane]) {
					out.push(<SingleSlot plane={plane} key={plane} eventId={this.props.eventId} refresh={this.singleEventRefresh}/>);
				}
			}
		}
		return out;
	};

	checkIfUserIsParticipant = () => {
		const api = JSON.parse(sessionStorage.apiData);

		for (let singleParticipant of this.state.participant) {
			if (singleParticipant.user.id === api.userInfo.id) {
				this.setState({
					userIsParticipant: true
				});
				return;
			}
		}

		this.setState({
			userIsParticipant: false
		});
	};

	onLeaveEvent = (event) => {
		let payload = {
			eventId: this.state.eventId
		};

		this.server.post("/leaveEvent", payload, this.requestAuth)
			.then((response) => {
				if (response.status === 200) {
					this.singleEventRefresh();
				} else {

				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	render() {

		const displayParticipant = () => {
			if (this.state.participant.length > 0) {
				return (
					<div className="ListOfParticipant">
						{this.state.participant.map((participant) => {
							return <SingleParticipant eventId={this.state.eventId} participant={participant} key={participant.user.id} isEventCreator={this.props.canCreateEvent} refresh={this.singleEventRefresh}/>
						})}
					</div>);
			} else {
				return null;
			}
		};

		const displayUserOption = () => {
			const distance = (new Date(this.props.event.eventDateTime).getTime()) - (new Date().getTime());
			const userOptionsTemplate = {
				leaveBt: (
					<div className="seLeaveEvent">
						<button onClick={this.onLeaveEvent}>Leave Event</button>
					</div>
				),
				slotList: (
					<div className="seSlotContainer">{this.displaySlotList()}</div>
				),
				closed: (
					<div className="seClosedEvent">Event Is Closed</div>
				)
			};

			if (distance <= 0) return userOptionsTemplate.closed;
				if(this.state.userIsParticipant){
					return userOptionsTemplate.leaveBt
				}else{
					return userOptionsTemplate.slotList;
				}
		};

		return (
			<div className={this.state.barIsShowing ? "SingleEvent barActive" : "SingleEvent"}>
				<div className="seUserDisplay">
					<div className="imgContainer" style={{backgroundImage: `url(${"https://throughtheinferno.com/wp-content/uploads/" + this.props.event.eventImgSrc})`}}>
					</div>
					<div className="nameContainer">
						<span className="name">{this.props.name !== undefined ? this.props.name : "Untitled Event"}</span>
						{this.canEditEvent()}
					</div>
					<div className="dateContainer">
						<Countdown dateTime={this.props.event.eventDateTime}/>
						<div className="formatedDateTime">{formatDate(this.props.event.eventDateTime)} zulu</div>
					</div>
					<div className={"mapContainer " + this.props.event.eventMap}>
						<span>{getEventMapName(this.props.event.eventMap)}</span>
					</div>
					<div className="descriptionContainer">
						<p>{this.props.event.eventDescription}</p>
					</div>
					{displayUserOption()}
				</div>

				{displayParticipant()}

				{this.props.canCreateEvent ? <EventAdminBar event={this.props.event} eventId={this.props.eventId} playerId={this.props.playerId} refresh={this.props.refresh}/> : null}
			</div>
		);
	}
}

//--------------------------------------------------------------------------------------------------------------------------------------------------- Admin Bar
class EventAdminBar extends Component {

	state = {
		edit: false
	};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	constructor(props) {
		super(props);
	}

	deleteEvent = () => {
		if (window.confirm('Are you sure you wish to delete this Event?')) {

			let eventParam = {
				eventId: this.props.eventId,
				playerId: this.props.playerId
			};

			this.server.post("deleteEvent", eventParam, this.requestAuth)
				.then((response) => {
					if (response.status === 200) {
						this.props.refresh();
					}
				})
				.catch(err => {
					console.log(err);
				});
		}
	};

	editEvent = () => {
		this.setState({
			edit: true
		});
	};

	render() {
		if (this.state.edit) {
			return <Redirect push to={{
				pathname: '/home/event/eventCreator',
			}}/>;
		}

		return (
			<div className="eventAdminBar">
				<span>Participants</span>
				<span onClick={this.editEvent}>Edit</span>
				<span onClick={this.deleteEvent}>Remove</span>
			</div>
		)
	}
}

//--------------------------------------------------------------------------------------------------------------------------------------------------- Single Participant
class SingleParticipant extends Component {

	state = {
		avatarData: undefined,
		nickname: undefined
	};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	componentDidMount() {
		this.fetchSingleAvatar()
	}

	fetchSingleAvatar() {
		const api = JSON.parse(sessionStorage.apiData);
		const {participant} = this.props;

		const avatarParam = {
			id: participant.user.id,
			avatar: participant.user.avatar,
			token: api.oauth.access_token,
			responseType: "arraybuffer"
		};

		this.server.post("getAvatar", avatarParam, this.requestAuth)
			.then((response) => {
				this.setState({
					avatarData: response.data
				})
			})
			.catch(err => {
				console.log("client getAvatar - " + err);
			});

		const nicknameParam = {
			id: participant.user.id
		};

		this.server.post("getNickname", nicknameParam, this.requestAuth)
			.then((response) => {
				this.setState({
					nickname: response.data
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	onForceRemoveParticipant = (event) => {
		if (window.confirm('Are you sure you wish to remove ' + this.state.nickname + ' from this Event ?\nThis will put them in the blacklist for this event')) {

			let payload = {
				eventId: this.props.eventId,
				userId: this.props.participant.user.id
			};

			this.server.post("/forceLeaveEvent", payload, this.requestAuth)
				.then((response) => {
					if (response.status === 200) {
						this.props.refresh();
					} else {
						console.log(response.data);
					}
				})
				.catch(err => {
					console.log(err);
				});
		}
	};

	render() {
		const {participant, isEventCreator} = this.props;

		return (
			<div className="singleParticipant">
				<div className="avatarContainer">
					{isEventCreator ?
						<img className="btAvatar" onClick={this.onForceRemoveParticipant} src="/media/fullImageX.png" alt=""/> : null}<img className="avatar" src={this.state.avatarData ? this.state.avatarData : avatarPlaceholder} alt="avatar"/>
				</div>
				<div className="text">
					<div>{this.state.nickname ? this.state.nickname : participant.username}</div>
					<div>{getPlaneName(participant.plane)}</div>
				</div>
			</div>
		)
	}
}