import React, {Component} from 'react';
import axios from "axios/index";
import {Redirect} from "react-router-dom";

export default class ApplicationReg extends Component {

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	constructor(props) {
		super(props);
	}

	getApplication = () => {
		this.server.post("/getApplication", null, this.requestAuth)
			.then((response) => {
				this.setState({
					application: response.data
				});
			})
			.catch(err => {
				console.log("Couldn't get application");
			});
	};

	componentDidMount() {
		this.getApplication();
	}

	render() {

		let applicationList = undefined;

		if (this.state) {

			if (this.state.application.length === 0) {
				applicationList = new Array(
					<div key="1">
						<p>No application are currently available</p>
					</div>
				);
			} else {
				applicationList = this.state.application.map((data) => {
					return <SingleApplication key={data.id} data={data} refresh={this.getApplication}/>
				});
			}
		}

		return (
			<div className="ApplicationReg card">
				<h1>Application Register</h1>
				<section className="appRegList">{applicationList}</section>
			</div>
		);
	}
}

export class SingleApplication extends Component {

	state = {};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.setState({
			detailsDisplay: false
		});
	}

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	displayDetails = (event, data) => {
		this.setState({
			detailsDisplay: !this.state.detailsDisplay
		});
	};

	removeApplication = id => {

		let payload = {
			applicationId: id
		};

		this.server.post("/removeApplication", payload, this.requestAuth)
			.then((response) => {
				this.props.refresh();
			})
			.catch(err => {
				console.log(err);
			});
	};

	render() {
		let data = this.props.data;

		let details = (
			<div className="detailsDisplay">
				<div>
					<span>Age</span>
					<p>{data.content.age}</p>
				</div>
				<div>
					<span>DCS in-game name</span>
					<p>{data.content.inGameName}</p>
				</div>
				<div>
					<span>Can you speak and understand English fluently ?</span>
					<p>{data.content.english}</p>
				</div>
				<div>
					<span>What is your aircraft of choice with which you want to qualify for the 107th ?</span>
					<p>{data.content.aircraft}</p>
				</div>
				<div>
					<span>Approximate flight time of your chosen aircraft ?</span>
					<p>{data.content.flightTime}</p>
				</div>
				<div>
					<span>What days/hours are you available ?</span>
					<p>{data.content.dayHour}</p>
				</div>
				<div>
					<span>Describe your proficiency with your chosen aircraft</span>
					<p>{data.content.proficiency}</p>
				</div>
				<div>
					<span>Describe your weaknesses with your chosen aircraft</span>
					<p>{data.content.weaknesses}</p>
				</div>
				<div>
					<span>Describe your goals as a member of the 107th JAS</span>
					<p>{data.content.goal}</p>
				</div>
				<div>
					<span>Do you need further assistance with SRS setup even after following our setup guide?</span>
					<p>{data.content.srs}</p>
				</div>
				<div>
					<span>List current 107th JAS members whom can sponsor your application:</span>
					<p>{data.content.list}</p>
				</div>
				<div>
					<span>Other comments</span>
					<p>{data.content.comments}</p>
				</div>
			</div>
		);

		return (
			<div key={data.id} className="singleApplication">
				<div className="topText">
					<span onClick={(event) => this.displayDetails(event, data)}>{data.content.nickname}</span>
					<span onClick={() => {
						this.removeApplication(data.id)
					}}>Archive Application</span>
				</div>
				{this.state.detailsDisplay ? details : null}
			</div>);
	}
}