import React, {Component} from 'react';
import axios from "axios";

export default class AdminFile extends Component {

	state = {
		files: [],
		currentFileName: undefined,
		currentFileContentRaw: undefined,
		isLoading: false
	};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	constructor(props) {
		super(props);
	}

	getFiles = () => {
		this.server.post("/getAdminFile", null, this.requestAuth)
			.then((response) => {
				this.setState({
					rawData: response.data
				});
				// console.log(response.data);
				this.parseRawData();
			})
			.catch(err => {
				console.log(err);
			});
	};

	parseRawData = () => {
		const parser = new DOMParser();
		let doc = parser.parseFromString(this.state.rawData, "text/html").getElementsByTagName("a");
		let tempArray = [];
		for (let item of doc) {
			if (item.text === "BannedClientsMerged.json") continue;
			if (item.text === "BannedClientsMerged.lua") continue;
			if (item.text === "chatlogs/") continue;
			tempArray.push(item.text)
		}
		tempArray.splice(0, 5);
		this.setState({
			files: tempArray
		});

	};

	getFileContent = filename => {
		if (filename === undefined) return;
		if (this.state.isLoading) return;

		this.setState({
			isLoading: true,
			currentFileName: filename
		});

		let data = {
			filename: filename
		};

		this.server.post("/getAdminFileContent", data, this.requestAuth)
			.then((response) => {
				this.setState({
					lookingAtBan: false,
					currentFileName: filename,
					currentFileContentRaw: response.data
				});
			})
			.then(() => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	displayData = () => {
		const files = this.state.files;
		let out = [];

		if (files.length <= 0) {
			return (<div>Loading</div>)
		}
		let key = 0;
		for (let single of files) {
			out.push(
				<div className="singleFile" key={key}>
					<span className="fileName">{single}</span>
					<button className={this.state.isLoading ? "greyedOut" : null} data-filename={single} onClick={() => (this.getFileContent(single))}>Look into</button>
				</div>
			);
			key++;
		}

		return out;
	};

	componentDidMount() {
		this.getFiles();
	}

	render() {

		return (
			<div className="AdminFile card">
				<div className="fileContainer">
					{this.displayData()}
				</div>
				<div className="detailContainer">
					<div className="detailTop">
						<span>Detail Look</span>
						<span>{this.state.currentFileName ? this.state.currentFileName : "Not looking at a file"}</span>
					</div>
					{this.state.isLoading ? <h1>It's loading ...</h1> : <code>{this.state.currentFileContentRaw ? this.state.currentFileContentRaw : "Not looking at a file"}</code>}
				</div>
			</div>
		);
	}
}