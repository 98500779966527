import React, {Component} from 'react';
import axios from "axios";

export default class Application extends Component {

	state = {
		appIsBeingSent: false,
		appIsSent: false,
		appIsAlreadySent: false,
		formInputStatus: {
			age: undefined,
			inGameName: undefined,
			english: undefined,
			aircraft: undefined,
			flightTime: undefined,
			dayHour: undefined,
			proficiency: undefined,
			weaknesses: undefined,
			goal: undefined,
			reference: undefined,
			understood: undefined
		}
	};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	componentDidMount() {
		this.setState({
			avatar: this.api.userInfo.avatar,
			discriminator: this.api.userInfo.discriminator,
			id: this.api.userInfo.id,
			username: this.api.userInfo.username,
		});

		this.checkIfUserCanSentApplication();
	}

	formAnswer = {
		userId: undefined,
		nickname: undefined,
		age: undefined,
		inGameName: undefined,
		english: undefined,
		aircraft: undefined,
		flightTime: undefined,
		dayHour: undefined,
		proficiency: undefined,
		weaknesses: undefined,
		goal: undefined,
		srs: undefined,
		list: undefined,
		comments: undefined,
		timezone: undefined
	};

	checkIfUserCanSentApplication = () => {
		this.server.post("/getApplication", null, this.requestAuth)
			.then((response) => {
				for (let singleApp of response.data) {
					if (singleApp.content.userId === this.state.id) {
						this.setState({
							appIsAlreadySent: true
						});
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	};


	onAgeChange = changeEvent => {
		const {formInputStatus} = this.state;
		let age = changeEvent.target.value;

		if (!isNaN(age)) {
			if (age > 0 && age < 100) {
				this.formAnswer.age = age;
				formInputStatus.age = undefined
			} else {
				formInputStatus.age = "Needs to be between 0 and 100";
			}
		} else {
			formInputStatus.age = "Needs to be number";
		}

		this.setState({
			formInputStatus
		});
	};

	onInGameNameChange = changeEvent => {
		const {formInputStatus} = this.state;

		if (changeEvent.target.value !== "" && changeEvent.target.value !== undefined) {
			this.formAnswer.inGameName = changeEvent.target.value;
			formInputStatus.inGameName = undefined;
		} else {
			formInputStatus.inGameName = "Needs to be filled out";
		}

		this.setState({
			formInputStatus
		});
	};

	onEnglishChange = changeEvent => {
		const {formInputStatus} = this.state;

		if (changeEvent.target.value !== undefined) {
			this.formAnswer.english = changeEvent.target.value;
			formInputStatus.english = undefined;
		}

		this.setState({
			formInputStatus
		});
	};

	onAircraftChange = changeEvent => {
		const {formInputStatus} = this.state;

		if (changeEvent.target.value !== "") {
			this.formAnswer.aircraft = changeEvent.target.value;
			formInputStatus.aircraft = undefined;
		} else {
			formInputStatus.aircraft = "You must choose one";
		}

		this.setState({
			formInputStatus
		});
	};

	onFlightTimeChange = changeEvent => {
		const {formInputStatus} = this.state;
		let time = changeEvent.target.value;

		if (!isNaN(time)) {
			if (time > 0) {
				this.formAnswer.flightTime = time;

				formInputStatus.flightTime = undefined
			} else {
				formInputStatus.flightTime = "Needs to be above 0";
			}
		} else {
			formInputStatus.flightTime = "Needs to be number";
		}

		this.setState({
			formInputStatus
		});
	};

	onDayHourChange = changeEvent => {
		const {formInputStatus} = this.state;

		if (changeEvent.target.value !== "" && changeEvent.target.value !== undefined) {
			this.formAnswer.dayHour = changeEvent.target.value;
			formInputStatus.dayHour = undefined;
		} else {
			formInputStatus.dayHour = "Needs to be filled out";
		}

		this.setState({
			formInputStatus
		});
	};

	onProficiencyChange = changeEvent => {
		const {formInputStatus} = this.state;

		if (changeEvent.target.value !== "" && changeEvent.target.value !== undefined) {
			this.formAnswer.proficiency = changeEvent.target.value;
			formInputStatus.proficiency = undefined;
		} else {
			formInputStatus.proficiency = "Needs to be filled out";
		}

		this.setState({
			formInputStatus
		});
	};

	onWeaknessesChange = changeEvent => {
		const {formInputStatus} = this.state;

		if (changeEvent.target.value !== "" && changeEvent.target.value !== undefined) {
			this.formAnswer.weaknesses = changeEvent.target.value;
			formInputStatus.weaknesses = undefined;
		} else {
			formInputStatus.weaknesses = "Needs to be filled out";
		}

		this.setState({
			formInputStatus
		});
	};

	onGoalChange = changeEvent => {
		const {formInputStatus} = this.state;

		if (changeEvent.target.value !== "" && changeEvent.target.value !== undefined) {
			this.formAnswer.goal = changeEvent.target.value;
			formInputStatus.goal = undefined;
		} else {
			formInputStatus.goal = "Needs to be filled out";
		}

		this.setState({
			formInputStatus
		});
	};

	onSrsChange = changeEvent => {
		const {formInputStatus} = this.state;

		if (changeEvent.target.value !== undefined) {
			this.formAnswer.srs = changeEvent.target.value;
			formInputStatus.srs = undefined;
		}

		this.setState({
			formInputStatus
		});
	};

	onListChange = changeEvent => {
		const {formInputStatus} = this.state;

		if(changeEvent.target.value !== undefined && changeEvent.target.value !== "" && changeEvent.target.value !== "none"){
			this.formAnswer.list = changeEvent.target.value;
			formInputStatus.reference = undefined;
		}else{
			formInputStatus.reference = "Must be filled out";
		}

		this.setState({
			formInputStatus
		});
	};

	onCommentsChange = changeEvent => {
		this.formAnswer.comments = changeEvent.target.value;
	};

	onUnderstoodChange = changeEvent =>{
		const {formInputStatus} = this.state;

		if (changeEvent.target.value === "yes") {
			formInputStatus.understood = undefined;
		}else{
			formInputStatus.understood = "This answer must be yes";
		}

		this.setState({
			formInputStatus
		});
	};

	onSendClick = () => {
		let canSend = true;

		this.formAnswer.userId = this.state.id;
		this.formAnswer.nickname = this.props.nickname ? this.props.nickname : this.state.username;

		const tempDate = new Date();

		this.formAnswer.timezone = (tempDate.getTimezoneOffset()) / 60;

		const {formInputStatus} = this.state;

		if (this.formAnswer.age === undefined) {
			formInputStatus.age = "Must be filled";
			canSend = false;
		}

		if (this.formAnswer.inGameName === undefined) {
			formInputStatus.inGameName = "Must be filled";
			canSend = false;
		}

		if (this.formAnswer.english === undefined) {
			formInputStatus.english = "You must choose one";
			canSend = false;
		}

		if (this.formAnswer.aircraft === undefined) {
			formInputStatus.aircraft = "You must choose one";
			canSend = false;
		}

		if (this.formAnswer.flightTime === undefined) {
			formInputStatus.flightTime = "Must be filled";
			canSend = false;
		}

		if (this.formAnswer.dayHour === undefined) {
			formInputStatus.dayHour = "Must be filled";
			canSend = false;
		}

		if (this.formAnswer.proficiency === undefined) {
			formInputStatus.proficiency = "Must be filled";
			canSend = false;
		}

		if (this.formAnswer.weaknesses === undefined) {
			formInputStatus.weaknesses = "Must be filled";
			canSend = false;
		}

		if (this.formAnswer.goal === undefined) {
			formInputStatus.goal = "Must be filled";
			canSend = false;
		}

		if (this.formAnswer.srs === undefined) {
			formInputStatus.srs = "You must choose one";
			canSend = false;
		}

		if(this.formAnswer.list === undefined){
			formInputStatus.reference = "Must be filled";
			canSend = false;
		}

		if(this.state.formInputStatus.understood !== undefined){
			formInputStatus.understood = "This answer must be yes";
			canSend = false;
		}

		this.setState({
			formInputStatus
		});

		if (canSend) {
			this.setState({
				appIsBeingSent: true
			});

			this.server.post("/applicationToDiscord", this.formAnswer, this.requestAuth)
				.then((response) => {
					if (response.status === 200) {
						this.setState({
							appIsBeingSent: false,
							appIsSent: true
						});
					}
				})
				.catch(err => {
					console.log(err);
				});

		}

	};


	render() {
		if (this.state.appIsBeingSent) {
			return (
				<section className="squadAppCard">
					<h3>Application is being sent</h3>
				</section>
			);
		} else if (this.state.appIsSent) {
			return (
				<section className="squadAppCard">
					<h3>Application has been received</h3>
				</section>
			);
		} else if (this.state.appIsAlreadySent) {
			return (
				<section className="squadAppCard">
					<h3>Application has already been sent</h3>
				</section>
			);
		} else {
			return (
				<section className="squadAppContainer">
					<div className="questionCard">
						<h1>107th JAS Squadron Application</h1>
						<p><strong>Make sure that all fields are complete before sending or your application will be rejected</strong></p>
						<p>If you are accepted within the 107th, you are expected to complete your Basic flight Qualification <strong>within 30 days</strong> of your application.
						   You'll also have a <strong>limited</strong> time line set by your instructor for completion of your airframe qualification.</p>
						<p>We are looking for members:</p>
						<ul>
							<li><strong>Who are very experienced community members</strong></li>
							<li>Who are good productive pilots who are good community members</li>
							<li>Who are Even keeled and driven to accomplish their goals</li>
							<li>That show good emotional intelligence but can be objective.</li>
							<li>Who are always learning, but always excelling.</li>
							<li>Who are strong, but communicate their weaknesses.</li>
							<li>Who are must be mature</li>
						</ul>
						<div className="reallyImportant">
							<p>We <strong>DO NOT</strong> do interviews.</p>
							<p> We <strong>DO NOT</strong> do try outs.</p>
							<p> If we don't already know who you are your application is going to be denied.  Come fly on our servers and get to know us <strong>BEFORE</strong> applying</p>
						</div>
						<div className="question">
							<label>Have you read and understood the condition and criteria for filing out an application ?</label>
							<div>
								<input type="text" className={this.state.formInputStatus.understood ? "inputError" : null} onChange={this.onUnderstoodChange}/>
								{this.state.formInputStatus.understood ? <span className="error">{this.state.formInputStatus.understood}</span> : null}
							</div>
							<div>
								<span><strong>You must list at least one 107th member whom can vouch for you:</strong></span>
								<span>If you don't have a reference the application will be denied</span>
							</div>
							<div>
								<input className={this.state.formInputStatus.reference ? "inputError" : null} type="text" onChange={this.onListChange}/>
								{this.state.formInputStatus.reference ? <span className="error">{this.state.formInputStatus.reference}</span> : null}
							</div>


							<label>Age</label>
							<div>
								<input type="text" className={this.state.formInputStatus.age ? "inputError" : null} onChange={this.onAgeChange}/>
								{this.state.formInputStatus.age ? <span className="error">{this.state.formInputStatus.age}</span> : null}
							</div>


							<label>DCS IN-GAME username</label>
							<div>
								<input type="text" className={this.state.formInputStatus.inGameName ? "inputError" : null} onChange={this.onInGameNameChange}/>
								{this.state.formInputStatus.inGameName ? <span className="error">{this.state.formInputStatus.inGameName}</span> : null}
							</div>


							<label>Can you speak and understand English fluently ?</label>
							<div className="radioInContainer">
								<label><input name="english" required type="radio" value="Yes" onChange={this.onEnglishChange}/>Yes</label>
								<label><input name="english" required type="radio" value="No" onChange={this.onEnglishChange}/>No</label>
								{this.state.formInputStatus.english ? <span className="error">{this.state.formInputStatus.english}</span> : null}
							</div>


							<label>What is your aircraft/role of choice with which you want to qualify for the 107th ?</label>
							<div>
								<select className={this.state.formInputStatus.aircraft ? "inputError" : null} onChange={this.onAircraftChange}>
									<option value="">--Please choose an option--</option>
									<option value="Flankers">Flankers</option>
									<option value="A-10C">A-10C</option>
									<option value="F/A-18C">F/A-18C</option>
									<option value="F-16C">F-16C</option>
									<option value="Ka-50">Ka-50</option>
									<option value="Controller">Controller</option>
								</select>
								{this.state.formInputStatus.aircraft ? <span className="error">{this.state.formInputStatus.aircraft}</span> : null}
							</div>


							<label>Approximate flight time of your chosen aircraft ?</label>
							<div>
								<input type="text" className={this.state.formInputStatus.flightTime ? "inputError" : null} onChange={this.onFlightTimeChange}/>
								{this.state.formInputStatus.flightTime ? <span className="error">{this.state.formInputStatus.flightTime}</span> : null}
							</div>


							<label>What days/hours are you available ?</label>
							<div>
								<input type="text" className={this.state.formInputStatus.dayHour ? "inputError" : null} onChange={this.onDayHourChange}/>
								{this.state.formInputStatus.dayHour ? <span className="error">{this.state.formInputStatus.dayHour}</span> : null}
							</div>


							<span>Describe your proficiency with your chosen aircraft</span>
							<div>
								<textarea className={this.state.formInputStatus.proficiency ? "inputError" : null} onChange={this.onProficiencyChange}/>
								{this.state.formInputStatus.proficiency ? <span className="error">{this.state.formInputStatus.proficiency}</span> : null}
							</div>


							<span>Describe your weaknesses with your chosen aircraft</span>
							<div>
								<textarea className={this.state.formInputStatus.weaknesses ? "inputError" : null} onChange={this.onWeaknessesChange}/>
								{this.state.formInputStatus.weaknesses ? <span className="error">{this.state.formInputStatus.weaknesses}</span> : null}
							</div>


							<span>Describe your goals as a member of the 107th JAS</span>
							<div>
								<textarea className={this.state.formInputStatus.goal ? "inputError" : null} onChange={this.onGoalChange}/>
								{this.state.formInputStatus.goal ? <span className="error">{this.state.formInputStatus.goal}</span> : null}
							</div>


							<span>Do you need further assistance with SRS setup even after following our setup guide?</span>
							<div className="radioInContainer">
								<label><input name="srs" required type="radio" value="Yes" onChange={this.onSrsChange}/>Yes</label>
								<label><input name="srs" required type="radio" value="No" onChange={this.onSrsChange}/>No</label>
								{this.state.formInputStatus.srs ? <span className="error">{this.state.formInputStatus.srs}</span> : null}
							</div>

							<span>Other comments</span>
							<textarea onChange={this.onCommentsChange}/>
						</div>
					</div>
					<div className="agreements">
						<p>
							By sending your application you agree to the previously mentioned terms and condition of application.
						</p>
					</div>
					<button onClick={this.onSendClick}>Send Application</button>
				</section>
			);
		}
	}
}