import React, {Component} from 'react';
import axios from "axios/index";
import {getPlaneName} from "../comon";
import {Redirect} from "react-router-dom";


export default class SingleSlot extends Component {

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	constructor(props) {
		super(props);

		this.onApply = this.onApply.bind(this);
	}

	onApply() {
		let api = JSON.parse(sessionStorage.apiData);

		let requestAuth = {
			headers: {
				userid: api.userInfo.id,
				token: api.oauth.access_token
			}
		};

		let data = {
			eventId: this.props.eventId,
			plane: this.props.plane,
			user: api.userInfo
		};

		this.server.post("/applyToEvent", data, requestAuth)
			.then((response) => {
				if (response.status === 200) {
					this.props.refresh()
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	render() {
		return (
			<button className="slot" onClick={this.onApply}>Join as {getPlaneName(this.props.plane)}</button>
		);
	}
}