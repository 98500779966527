import React, {Component} from 'react';
import axios from "axios";

export default class Chatlog extends Component {
	state = {
		serverListLoaded: false,
		serverList: undefined,
		currentServerName: undefined,
		currentServerPath: undefined,
		monthListing: undefined,
		individualListings: undefined,
		detailView: undefined
	};

	constructor(props) {
		super(props);
	}

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	getServers = () => {
		let data = {
			filename: "chatlogs/"
		};

		this.server.post("/getAdminFileContent", data, this.requestAuth)
			.then((response) => {
				const parser = new DOMParser();
				let doc = parser.parseFromString(response.data, "text/html").getElementsByTagName("a");
				let tempArray = [];
				for (let item of doc) {
					tempArray.push(item.text)
				}
				tempArray.splice(0, 5);

				this.setState({
					serverList: tempArray
				});

			})
			.then(() => {
				this.setState({
					serverListLoaded: true
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	displayServer = () => {
		let out = [];
		let key = 0;
		for (let singleServer of this.state.serverList) {
			let niceLookingServerName;
			switch (singleServer) {
				case "SOCAL1/":
					niceLookingServerName = "Socal 1";
					break;
				case "SOCAL2/":
					niceLookingServerName = "Socal 2";
					break;
				case "SOCAL3/":
					niceLookingServerName = "Socal 3";
					break;
				case "SOCAL4/":
					niceLookingServerName = "Socal 4";
					break;
				case "NORCAL1/":
					niceLookingServerName = "Norcal 1";
					break;
				default:
					niceLookingServerName = singleServer;
			}

			out.push(
				<div className="serverButton card" key={key} onClick={() => (this.getDirectoryListing(singleServer, niceLookingServerName))}>
					{niceLookingServerName ? niceLookingServerName : "Unamed Server"}
				</div>
			);
			key++;
		}
		return out;
	};

	getDirectoryListing = (directory, serverName) => {
		this.setState({
			currentServerName: serverName,
			currentServerPath: directory,
			monthListing: undefined,
			individualListings: undefined
		});

		let data = {
			filename: "chatlogs/" + directory
		};
		this.server.post("/getAdminFileContent", data, this.requestAuth)
			.then((response) => {
				const parser = new DOMParser();
				let doc = parser.parseFromString(response.data, "text/html").getElementsByTagName("a");
				let tempArray = [];
				for (let item of doc) {
					tempArray.push(item.text)
				}
				tempArray.splice(0, 5);

				this.parseListing(tempArray);
			})
			.catch(err => {
				console.log(err);
			});
	};

	parseListing = (listing) => {
		let month = {
			Jan: [],
			Feb: [],
			Mar: [],
			Apr: [],
			May: [],
			Jun: [],
			Jul: [],
			Aug: [],
			Sep: [],
			Oct: [],
			Nov: [],
			Dec: []
		};

		// Check first 3 letter for month
		for (let singleListing of listing) {
			let singleListingMonth = singleListing.slice(0, 3);
			try{
				month[singleListingMonth].push(singleListing);
			}catch (e){
				continue;
			}
		}
		// Filter for months with zero entries & build display
		let filteredMonth = [];
		for (let [key, singleMonth] of Object.entries(month)){
			if (singleMonth.length <= 0) continue;

			filteredMonth.push(
				<div className="month" key={key} onClick={()=>{this.displayIndividualListing(singleMonth)}}>
					{key}
				</div>
			);
		}

		this.setState({
			monthListing: filteredMonth
		});
	};

	displayIndividualListing = (month) =>{
		let out = [];
		for (let [index, singleListing] of month.entries()){
			out.push(
				<div className="singleFile" key={index} onClick={()=>{this.openDetailView(singleListing)}}>
					{singleListing}
				</div>
			);
		}
		this.setState({
			individualListings: out
		});
	};

	openDetailView = (file) =>{
		let data = {
			filename: "chatlogs/" + this.state.currentServerPath + file
		};
		this.server.post("/getAdminFileContent", data, this.requestAuth)
			.then((response) => {
				let out = (
					<div className="detailView">
						<div className="close" onClick={this.closeDetailView}>
							close
						</div>
						<code className="code">
							{response.data}
						</code>
					</div>
				);
				this.setState({
					detailView: out
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	closeDetailView = () =>{
		this.setState({
			detailView: undefined
		});
	};

	componentDidMount = () => {
		this.getServers();
	};

	render() {
		return (
			<div className="Chatlog">

				<div className="serverContainer">
					{this.state.serverListLoaded ? this.displayServer() : "Loading Servers"}
				</div>
				<div className="serverChoice">
					{this.state.currentServerName ? this.state.currentServerName : "No Server chosen"}
				</div>
				<div className="gridContainer">
					<div className="monthContainer">
						{this.state.monthListing}
					</div>
					<div className="fileContainer">
						{this.state.individualListings}
					</div>
				</div>
				{this.state.detailView ? this.state.detailView : undefined}
			</div>
		);
	}
}