import React, {Component} from "react";
import axios from "axios";

export default class FlightStrip extends Component {

	state = {
		raw: [
			[],
			[],
			[],
			[],
			[]
		]
	};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});
	api = JSON.parse(sessionStorage.apiData);
	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	componentDidMount() {
		this.init();
	}

	componentWillUnmount() {

	}

	init = () => {
		this.webSocket = new WebSocket("wss://reorg.throughtheinferno.com:8082/");

		this.webSocket.onopen = (event) => {
			//this.webSocket.send("Here's some text that the server is urgently awaiting!");
		};

		this.webSocket.onmessage = (event) => {
			try {
				let msg = JSON.parse(event.data);
				if (!msg.msgType) return new Error("msgType is not defined");
				switch (msg.msgType) {
					case "update":
						console.log("Update");
						this.setState({
							raw: msg.data
						});
						break;
					default:
						return new Error("msgType does not match");
				}

			} catch (e) {
				console.log(e);
			}

		}
	};

	updateFlightStrip = (ucid, action, newValue) => {
		let out = this.state.raw;

		for (let category in out) {
			for (let [key, single] of Object.entries(out[category])) {
				if (single.ucid === ucid) {
					console.log(single);
					switch (action) {
						case "moveUp":
							if (eval(category) === 0) return;
							out[eval(category)].splice(key, 1);
							out[(eval(category) - 1)].push(single);
							this.setState({
								raw: out
							}, () => {
								this.sendUpdate();
							});
							return;
						case "moveDown":
							if (eval(category) === 4) return;
							out[eval(category)].splice(key, 1);
							out[(eval(category) + 1)].push(single);
							this.setState({
								raw: out
							}, () => {
								this.sendUpdate();
							});
							return;
						case "updateTransponder":
							out[category][key]["transponder"] = newValue;
							this.setState({
								raw: out
							}, () => {
								this.sendUpdate();
							});
							return;
						default:
							console.log("Action Unknown");
							return;
					}
				}
			}
		}
	};

	sendUpdate = () => {
		try {
			let msg = {
				msgType: "update",
				data: this.state.raw
			};

			this.webSocket.send(JSON.stringify(msg));
		} catch (e) {
			console.log("couldn't send update")
		}

	};

	render = () => {
		let display = [
			[],
			[],
			[],
			[],
			[]
		];

		for (let category in this.state.raw) {
			for (let [key, single] of Object.entries(this.state.raw[eval(category)])) {
				display[eval(category)].push(
					<SingleFlightStrip details={single} update={this.updateFlightStrip} key={single.ucid}/>
				)
			}
		}

		return (
			<div className="FlightStrip">
				<h1>Flight Strip System</h1>
				<div className="gridContainer">
					<div className="category">
						<h2>All Players</h2>
						<div>
							{display[0] ? display[0] : null}
						</div>
					</div>
					<div className="category">
						<h2>Ground</h2>
						<div>
							{display[1] ? display[1] : null}
						</div>
					</div>
					<div className="category">
						<h2>Tower</h2>
						<div>
							{display[2] ? display[2] : null}
						</div>
					</div>
					<div className="category">
						<h2>Approach & Arrivals</h2>
						<div>
							{display[3] ? display[3] : null}
						</div>
					</div>
					<div className="category">
						<h2>Awacs</h2>
						<div>
							{display[4] ? display[4] : null}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class SingleFlightStrip extends Component {
	state = {
		transponder: "",
		name: "",
		ucid: "",
		remarks: "",
		status: ""
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.setState(this.props.details);
	}

	updateTransponder = (e) => {
		let code = e.currentTarget.value;

		this.setState({
			transponder: code
		}, () => {
			this.props.update(this.state.ucid, "updateTransponder", code);
		});
	};

	render() {
		let classes = "SingleFlightStrip " + (this.state.status ? this.state.status : "");

		return (
			<div className={classes}>
				<div className="top">
					<span className="name">{this.state.name ? this.state.name : "Unknown"}</span>
					<input type="text" className="transponder" placeholder="Transpoder" value={this.state.transponder} onChange={this.updateTransponder}/>
				</div>
				<div className="remark">
					<input type="text" placeholder="remarks"/>
					<span>{this.state.ucid}</span>
				</div>
				<div className="buttonsContainer">
					<div className="button">A2A</div>
					<div className="button">A2G</div>
					<div className="button">None</div>
					<div className="button moveUp" onClick={() => {
						this.props.update(this.state.ucid, "moveUp")
					}}>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 22v2h-10v-2h10zm0-4h-10v2h10v-2zm-10-7v5h10v-5h6l-11-11-11 11h6z"/></svg>
						</svg>
					</div>
					<div className="button moveDown" onClick={() => {
						this.props.update(this.state.ucid, "moveDown")
					}}>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 2v-2h10v2h-10zm0 4h10v-2h-10v2zm10 7v-5h-10v5h-6l11 11 11-11h-6z"/></svg>
						</svg>
					</div>
				</div>
			</div>
		)
	}
}