import React, {Component} from 'react';
import Top from "./Top";
import Sidebar from "./Sidebar";
import axios from "axios";
import {avatarPlaceholder} from "./image";
import Event from "./event/Event";
import EventCreator from "./event/EventCreator";
import DiscordPoll from "./admin/DiscordPoll";
import Application from "./application/Application";
import ApplicationReg from "./admin/ApplicationReg";
import AdminFile from "./admin/AdminFile";
import BanList from "./admin/BanList";
import {Route, Switch} from "react-router-dom";
import HomePage from "./HomePage";
import Chatlog from "./admin/Chatlog";
import FlightStrip from "./controller/FlightStrip";

export default class Home extends Component {

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	constructor(props) {
		super(props);

		this.state = {
			token: this.api.oauth.access_token,
			refresh_token: this.api.oauth.refresh_token,
			token_scope: this.api.oauth.scope,
			token_type: this.api.oauth.token_type,
			avatar: this.api.userInfo.avatar,
			discriminator: this.api.userInfo.discriminator,
			id: this.api.userInfo.id,
			locale: this.api.userInfo.locale,
			mfa_enabled: this.api.userInfo.mfa_enabled,
			nickname: undefined,
			avatarData: undefined,
			roles: {}
		};

	}

	componentDidMount() {
		sessionStorage.setItem("userData", JSON.stringify({}));

		this.fetchAvatar();
		this.fetchNickname();
		this.fetchRole();
	}

	fetchAvatar = () => {
		const avatarParam = {
			id: this.state.id,
			avatar: this.state.avatar,
			token: this.state.token,
			responseType: "arraybuffer"
		};

		this.server.post("getAvatar", avatarParam, this.requestAuth)
			.then((response) => {
				this.setState({
					avatarData: response.data
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	fetchNickname = () => {
		const nicknameParam = {
			id: this.state.id
		};

		this.server.post("getNickname", nicknameParam, this.requestAuth)
			.then((response) => {
				this.setState({
					nickname: response.data
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	fetchRole = () => {
		const param = {
			id: this.state.id
		};

		this.server.post("getRole", param, this.requestAuth)
			.then((response) => {
				console.log(response.data);
				this.setState({
					roles: response.data
				});
			})
			.catch(err => {
				console.log(err);
			});
	};


	render() {
		let avatarUrl = avatarPlaceholder;
		let displayName = this.state.username;

		if (this.state.avatarData !== undefined) avatarUrl = this.state.avatarData;
		if (this.state.nickname !== undefined) displayName = this.state.nickname;


		const routes = (
			<Switch>
				<Route exact path="/home">
					<HomePage/>
				</Route>
				<Route exact path="/home/event">
					<Event eventCoord={this.state.roles.eventCoordinator} discordId={this.state.id}/>
				</Route>
				<Route exact path="/home/event/eventCreator">
					<EventCreator/>
				</Route>
				<Route exact path="/home/admin/O_ClubPolls">
					<DiscordPoll authorName={this.state.nickname}/>
				</Route>
				<Route exact path="/home/application">
					<Application nickname={this.state.nickname}/>
				</Route>
				<Route exact path="/home/admin/applicationRegister">
					<ApplicationReg/>
				</Route>
				<Route exact path="/home/admin/adminFile">
					<AdminFile/>
				</Route>
				<Route exact path="/home/admin/Chatlog">
					<Chatlog/>
				</Route>
				<Route exact path="/home/admin/banList">
					<BanList/>
				</Route>
				<Route exact path="/home/controller/flightStrip">
					<FlightStrip/>
				</Route>
			</Switch>
		);

		return (
			<div className="Home">
				<Top username={displayName} avatar={avatarUrl}/>
				<Sidebar roles={this.state.roles}/>
				<div className="main">
					{routes}
				</div>
			</div>
		);
	}
}