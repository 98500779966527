import React, {Component} from 'react';
import {Link} from "react-router-dom";


export default class Sidebar extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		let specificRender = {
			canSeeQual: false,
			canSeeEvent: false,
			canSeeApplication: false,
			canSeeOClubPoll: false,
			canSeeApplicationReg: false
		};

		let roles = this.props.roles;

		specificRender.canSeeEvent = true;


		//--------------------------------------------------------------------- Member
		if(!(roles.squadronRecruit || roles.squadronMember)){
			specificRender.canSeeApplication = true;
		}

		//--------------------------------------------------------------------- Squadron Recruit
		if(roles.squadronRecruit){
			specificRender.canSeeQual = true;
		}

		//--------------------------------------------------------------------- Squadron Member
		if(roles.squadronMember){
			specificRender.canSeeQual = true;
		}

		//--------------------------------------------------------------------- Moderator
		if(roles.moderator){
			specificRender.canSeeOClubPoll = true;
			specificRender.canSeeApplication = true;
			specificRender.canSeeApplicationReg = true;
		}

		return (
			<nav className="Sidebar">
				<ul>
					<li><Link to={"/home"}>Home</Link></li>
					{/*specificRender.canSeeEvent*/ false ? <li><Link to={"/home/event"}>Events</Link></li> : null}
					{specificRender.canSeeApplication ? <li><Link to={"/home/application"}>Application</Link></li> : null}

					{specificRender.canSeeOClubPoll ? <li className="sidebarCategory"><span>Controller</span></li> : null}
					{specificRender.canSeeOClubPoll ? <li><Link to={"/home/controller/flightStrip"}>Flight Strip</Link></li> : null}

					{specificRender.canSeeOClubPoll ? <li className="sidebarCategory"><span>Administration</span></li> : null}
					{specificRender.canSeeOClubPoll ? <li><Link to={"/home/admin/O_ClubPolls"}>Poll Maker</Link></li> : null}
					{specificRender.canSeeApplicationReg ? <li><Link to={"/home/admin/applicationRegister"}>Application Register</Link></li> : null}
					{specificRender.canSeeOClubPoll ? <li><Link to={"/home/admin/adminFile"}>Admin Files</Link></li> : null}
					{specificRender.canSeeOClubPoll ? <li><Link to={"/home/admin/chatlog"}>Chat Logs</Link></li> : null}
					{specificRender.canSeeOClubPoll ? <li><Link to={"/home/admin/banList"}>Ban List</Link></li> : null}
				</ul>
			</nav>
		);
	}
}