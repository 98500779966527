import React, {Component} from 'react';
import axios from "axios/index";
import {Redirect} from "react-router-dom";

export default class DiscordPoll extends Component {

	state = {
		question: "",
		options: [],
		channelList: undefined,
		selectedChannelId: undefined
	};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.fetchChannelList();
	}

	fetchChannelList = () => {
		this.server.post("/getTextChannel", this.state, this.requestAuth)
			.then((response) => {
				this.setState({
					channelList: response.data
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	displayChannelList = () => {
		let output = [];
		const list = this.state.channelList;

		for (let single in list) {
			output.push(<option key={list[single][1]} value={list[single][1]}>{list[single][0]}</option>);
		}

		return output;
	};

	onChannelChange = e => {
		this.setState({
			selectedChannelId: e.target.value
		});
	};

	onQuestionChange = e => {
		this.setState({
			question: e.target.value
		});
	};

	handleAddOption = e => {
		let tempOptions = this.state.options;

		let test = Math.round(Math.random() * 9999);

		let newOption = {
			text: "",
			key: test
		};

		tempOptions.push(newOption);

		this.setState({
			options: tempOptions,
		});
	};

	handleRemoveOption = removeOption => {
		const index = this.state.options.findIndex((sOption) => {
			return sOption.key === removeOption.key;
		});
		let tempOptions = this.state.options;
		tempOptions.splice(index, 1);

		this.setState({
			options: tempOptions
		});
	};

	onOptionsTextChange = (changeOption, event) => {
		let tempOptions = this.state.options;
		const index = this.state.options.findIndex((sOption) => {
			return sOption.key === changeOption.key;
		});

		tempOptions[index].text = event.target.value;

		this.setState({
			options: tempOptions
		});
	};

	displayOptions() {
		let out = [];

		for (let sOption of this.state.options) {
			out.push(
				<li key={sOption.key.toString()}>
					<input type="text" onChange={(event)=> this.onOptionsTextChange(sOption, event)} placeholder="some poll Option"/>
					<span onClick={()=> this.handleRemoveOption(sOption)}>Remove</span>
				</li>
			)
		}

		return out;
	}

	checkCanSend = () => {
		// Check if it has more than 2 question
		if (this.state.options.length < 2) return false;
		// Check If the question field is filled
		if(!this.state.question.length) return false;
		// Check If a channel has been selected
		if(!this.state.selectedChannelId) return false;
		// Check if every option has text
		for (let sOption of this.state.options) {
			if (sOption.text === "") {
				return false;
			}
		}

		// Default option
		return true;
	};

	handleSendPoll = e => {
		if(!this.checkCanSend()) return;

		const payload = {
			question: this.state.question,
			channel: this.state.selectedChannelId,
			options: this.state.options
		};

		this.server.post("/postPoll", payload, this.requestAuth)
			.then((response) => {
				if(response.status === 200){
					this.setState({
						redirect: true
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	render() {

		if (this.state.redirect) {
			return <Redirect to='/home'/>;
		}

		return (
			<div className="DiscordPoll">
				<h1>Poll Maker</h1>
				<div className="channelListContainer">
					<select onChange={this.onChannelChange}>
						<option key="123" value="">- Choose a Channel -</option>
						{this.displayChannelList()}
					</select>
				</div>
				<div className="questionContainer">
					<input type="text" placeholder="Are we ok banning that guy ?" onChange={this.onQuestionChange}/>
				</div>
				<div className="optionContainer">
					<ul className="optionList">
						{this.displayOptions()}
					</ul>
				</div>
				<div className="controls">
					<span onClick={this.handleAddOption}>Add Option</span>
					<span onClick={this.handleSendPoll} className={this.checkCanSend() ? null : "grayedOut"}>Send Poll</span>
				</div>
			</div>
		);
	}
}