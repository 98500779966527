import React, {Component} from 'react';
import axios from "axios";
import {formatDate, generateColors} from "../comon";
import ChartDonut from "../chart/ChartDonut";
import ChartAreaChart from "../chart/ChartAreaChart";
import ChartBar from "../chart/ChartBar";

export default class BanList extends Component {
	state = {
		isLoading: false,
		errorLoading: false,
		currentFileContent: undefined,
		currentFileContentRaw: undefined,
		specialBan: undefined,
		banNumber: undefined,
		sortedByTime: undefined,
		displayRaw: false,
		rawTab: [],
		banPerAdmin: {},
		stats: undefined
	};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.getBanList();
	}

	getBanList = () => {
		this.setState({
			isLoading: true
		});

		let data = {
			filename: "BannedClientsMerged.lua"
		};

		this.getJsonList();

		this.server.post("/getAdminFileContent", data, this.requestAuth)
			.then((response) => {
				this.setState({
					currentFileContentRaw: response.data
				});
			})
			.then(() => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					errorLoading: true,
					isLoading: false
				});
				console.log(err);
			});
	};

	getJsonList = () => {
		let data = {
			filename: "BannedClientsMerged.json"
		};

		let tempBanPerAdmin = {
			recoil: 0,
			butterfly: 0,
			rz: 0,
			deadly: 0,
			hammer: 0,
			whiter: 0,
			iroc: 0,
			holt: 0,
			topper: 0,
			tuna: 0,
			tyncan: 0,
			zeus: 0,
			jaffa: 0
		};

		this.server.post("/getAdminFileContent", data, this.requestAuth)
			.then((response) => {
				const filterOldMod = (ucid) => {
					let out;
					switch (ucid) {
						case "701ccb63573039cdd2cb879287156892":
						case "a1575a9f0905c19c26e073940f0699d7":
						case "7c521285a5f802862c9d31a3d04e7e92":
						case "80654cf637e1e975e7a030516a82464b":
						case "09d25b6fa5fff54c5bc3a4afe7eee978":
						case "e507fbc33f3f1c6193ede7e2b277be5b":
						case "844dfe1dd867d604f94ecc3d15e8d449":
						case "2c1037ff0bd6d10d7b2926f2ea372f09":
							out = true;
							break;
						default:
							out = false;
							break;
					}
					return out;
				};

				let rawTab = [];

				for (const [ucid, data] of Object.entries(response.data.slmod_banned_ucids)) {
					if (data.bannedBy.ucid === "host" || data.bannedBy.ucid === "f7f7854c7d124d39904fb6beefeb2879") data.bannedBy = {name: "Host", ucid: "gggggggggggggggggggggggggggggggg"};
					if (data.bannedBy.ucid === undefined) data.bannedBy = {name: "Autoban", ucid: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"};
					if (data.bannedBy.name === "neverforget") data.bannedBy = {name: "neverforget", ucid: "yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy"};
					if (filterOldMod(data.bannedBy.ucid)) data.bannedBy = {name: "Legacy Mod", ucid: "zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz"};

					let temp = this.ucidToName(data.bannedBy.ucid);
					if (temp) data.bannedBy.name = this.ucidToName(data.bannedBy.ucid);
					rawTab.push(data);
				}

				this.setState({
					rawTab: rawTab,
					banPerAdmin: tempBanPerAdmin
				});

				this.displayFormatedList();
				this.calculateModStats();
				this.calculateMonthStats();
				this.calculateBanTimeline();
				this.calculateBanHours();
			})
			.then(() => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	sortListByTime = () => {
		let rawTab = this.state.rawTab;

		function sortOrder() {
			return (a, b) => {
				if (a["time"] > b["time"]) {
					return -1;
				} else if (a["time"] < b["time"]) {
					return 1;
				} else {
					return 0;
				}
			}
		}

		rawTab.sort(sortOrder());

		this.setState({
			rawTab: rawTab
		});
		this.displayFormatedList();
	};

	sortListByMod = () => {
		let rawTab = this.state.rawTab;
		let tempTab = [];

		for (let i = 0; i < rawTab.length; i++) {
			if (rawTab[i]["bannedBy"]["ucid"] === undefined || rawTab[i]["bannedBy"]["ucid"] === "host") {
				tempTab.push(rawTab[i]);
				rawTab.splice(i, 1);
			}
		}

		function sortOrder() {
			return (a, b) => {
				if (a["bannedBy"]["ucid"] > b["bannedBy"]["ucid"]) {
					return 1;
				} else if (a["bannedBy"]["ucid"] < b["bannedBy"]["ucid"]) {
					return -1;
				} else {
					return 0;
				}
			}
		}

		rawTab.sort(sortOrder());

		for (let s of tempTab) {
			rawTab.push(s);
		}

		this.setState({
			rawTab: rawTab
		});
		this.displayFormatedList();
	};

	sortListByUCID = () => {
		let rawTab = this.state.rawTab;

		function sortOrder() {
			return (a, b) => {
				if (a["ucid"] > b["ucid"]) {
					return -1;
				} else if (a["ucid"] < b["ucid"]) {
					return 1;
				} else {
					return 0;
				}
			}
		}

		rawTab.sort(sortOrder());

		this.setState({
			rawTab: rawTab
		});
		this.displayFormatedList();
	};

	truncate = (input) => {
		if (input === undefined) return;
		if (input.length > 30)
			return input.substring(0, 30) + '...';
		else
			return input;
	};

	onDisplayRawChange = changeEvent => {
		this.setState({
			displayRaw: changeEvent.target.checked
		});
	};

	calculateModStats = () => {
		let tempStats = {};
		let temptab = [];
		let out = [];

		for (const data of this.state.rawTab) {
			if (tempStats.hasOwnProperty(data.bannedBy.ucid)) {
				tempStats[data.bannedBy.ucid]++;
			} else {
				tempStats[data.bannedBy.ucid] = 1;
			}
		}

		for (const [ucid, data] of Object.entries(tempStats)) {
			temptab.push([ucid, data]);
		}

		function sortOrder() {
			return (a, b) => {
				if (a[1] > b[1]) {
					return -1;
				} else if (a[1] < b[1]) {
					return 1;
				} else {
					return 0;
				}
			}
		}

		temptab.sort(sortOrder());

		let donutMod = [];

		for (const s of temptab) {
			let name = this.ucidToName(s[0]);

			if (name) {
				donutMod.push({
					label: name,
					value: s[1]
				});

				out.push(
					<tr key={s[0]}>
						<td>{name}</td>
						<td>{s[1]}</td>
					</tr>
				);
			}
		}


		this.setState({
			stats: out,
			donutMod: donutMod
		});

	};

	convertTime = (timestamp) => {
		let time = new Date(timestamp * 1000);
		let year = time.getUTCFullYear();
		let month = time.getUTCMonth();
		let day = time.getUTCDate();
		let hour = time.getUTCHours();

		return {
			year: year,
			month: month,
			day: day,
			hour: hour,
		};
	};

	calculateMonthStats = () => {
		let yearMonth = {};
		for (const data of this.state.rawTab) {
			let time = this.convertTime(data.time);
			// Year
			if (yearMonth.hasOwnProperty(time.year)) {
				// temp[year]++;
			} else {
				yearMonth[time.year] = {};
			}

			// Month
			if (yearMonth[time.year].hasOwnProperty(time.month)) {
				yearMonth[time.year][time.month]++;
			} else {
				yearMonth[time.year][time.month] = 1;
			}
		}

		let tempMonth = {
			0: 0,
			1: 0,
			2: 0,
			3: 0,
			4: 0,
			5: 0,
			6: 0,
			7: 0,
			8: 0,
			9: 0,
			10: 0,
			11: 0
		};
		for (const data of this.state.rawTab) {
			let time = this.convertTime(data.time);
			tempMonth[time.month]++;
		}
	};

	calculateBanTimeline = () => {
		const cutOffDate = () => {
			let out = new Date();
			out.setUTCFullYear(out.getUTCFullYear() - 1, out.getUTCMonth());
			return out;
		};

		let banPerUser = {};

		// Iterate over all ban
		for (let i = 0; i < this.state.rawTab.length; i++) {
			const data = this.state.rawTab[i];

			let time = this.convertTime(data.time);
			let ucid = data.bannedBy.ucid;

			if (ucid === undefined) console.log(data);

			// Filter for ban More than 12 month ago
			if (time.year < cutOffDate().getUTCFullYear()) continue;
			if (time.year === cutOffDate().getUTCFullYear() && time.month <= cutOffDate().getUTCMonth()) continue;

			// Adds user to the list
			if (!banPerUser[ucid]) banPerUser[ucid] = {};

			// Adds year field to user
			if (!banPerUser[ucid][time.year]) banPerUser[ucid][time.year] = {};

			// Adds Month field to user or increment month field
			if (!banPerUser[ucid][time.year][time.month]) {
				banPerUser[ucid][time.year][time.month] = 1;
			} else {
				banPerUser[ucid][time.year][time.month]++;
			}
		}

		let dataSets = [];

		const thisYearMonthTemplate = () => {
			let out = {};
			const today = new Date();
			for (let i = today.getUTCMonth(); i >= 0; i--) {
				out[i] = 0;
			}
			return out;
		};

		const lastYearMonthTemplate = () => {
			let out = {};
			const today = new Date();
			for (let i = today.getUTCMonth() + 1; i <= 11; i++) {
				out[i] = 0;
			}
			return out;
		};

		// Iterate over all Moderators
		for (const [mod, data] of Object.entries(banPerUser)) {
			const today = new Date();
			let dataForDataSet = [];

			// Adds data to empty Month last year
			if (data[cutOffDate().getUTCFullYear()]) {
				data[cutOffDate().getUTCFullYear()] = Object.assign(lastYearMonthTemplate(), data[cutOffDate().getUTCFullYear()]);
			} else {
				banPerUser[mod][cutOffDate().getUTCFullYear()] = lastYearMonthTemplate();
			}

			// Adds data to empty Month current year
			if (data[today.getUTCFullYear()]) {
				data[today.getUTCFullYear()] = Object.assign(thisYearMonthTemplate(), data[today.getUTCFullYear()]);
			} else {
				banPerUser[mod][today.getUTCFullYear()] = thisYearMonthTemplate();
			}

			for (const [year, month] of Object.entries(data)) {
				for (const [singleMonth, numBan] of Object.entries(month)) {
					dataForDataSet.push(numBan);
				}
			}

			let color = generateColors(1);
			let singleDataSet = {
				label: this.ucidToName(mod),
				borderColor: color[0],
				backgroundColor: color[0],
				data: dataForDataSet,
				fill: false,
				yAxisID: "yAxis",
			};
			dataSets.push(singleDataSet);
		}

		const generateLabelsYear = () => {
			const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			const today = new Date();
			let out = [];

			// Last year
			for (let i = today.getUTCMonth() + 1; i <= 11; i++) {
				out.push(MONTHS[i] + " " + String(today.getUTCFullYear() - 1));
			}
			// This year
			for (let i = 0; i <= today.getUTCMonth(); i++) {
				out.push(MONTHS[i] + " " + String(today.getUTCFullYear()));
			}

			return out;
		};

		this.setState({
			areaChartData: dataSets,
			areaChartLabels: generateLabelsYear()
		});
	};

	calculateBanHours = () => {
		let dataHour = {
			0: 0,
			1: 0,
			2: 0,
			3: 0,
			4: 0,
			5: 0,
			6: 0,
			7: 0,
			8: 0,
			9: 0,
			10: 0,
			11: 0,
			12: 0,
			13: 0,
			14: 0,
			15: 0,
			16: 0,
			17: 0,
			18: 0,
			19: 0,
			20: 0,
			21: 0,
			22: 0,
			23: 0,
		};

		for (const data of this.state.rawTab) {
			let time = this.convertTime(data.time);
			dataHour[time.hour]++;
		}

		let dataSet = [{
			label: "Bans per Hour",
			data: Object.values(dataHour),
			fill: true,
			backgroundColor: "rgba(50,50,255,0.5)",
		}];

		this.setState({
			banPerHour: dataSet
		});
	};

	ucidToName = (ucid) => {
		let out;

		switch (ucid) {
			case "a0a4655120bff0963b54cd6374651aaa":
				out = "[107th] Recoil 016";
				break;
			case "cf2dc3ccae83aed02551dbd46ee6631d":
				out = "[107th] Butterfly 055";
				break;
			case "0792a82481d795d227ac6e8bbd2dc5b8":
				out = "[107th] Rz 022";
				break;
			case "41529a3770b6e58c2737c33cf4fbfcb0":
				out = "[107th] Deadlyfishes 100";
				break;
			case "286e8d3277b18a39272db2d17b003c12":
				out = "[107th] Hammer 069";
				break;
			case "16bd7fec5b611e0c42d74380d8381bba":
				out = "[107th] Whither 007";
				break;
			case "b92e68e0122ee9e6ab975a267e3c8728":
				out = "[107th] Iroc 048";
				break;
			case "9506cd0011ee3bc2f4eb1b6bdd3b7297":
				out = "[107th] Holt 012";
				break;
			case "4c995a67cf38b4d8183093473f4d99ed":
				out = "Topper | 096";
				break;
			case "e6f0dd6f146e5c6b691dc619641901dd":
				out = "Tuna 088";
				break;
			case "aa1469018d17e81f80c1e13a9f74aa60":
				out = "[107th] Tyncan 042";
				break;
			case "0af070134779438264b5953e96841c71":
				out = "[107th] Zeus 037";
				break;
			case "5dadf9fb37c0168fef9a0aee3daa57af":
				out = "[107th] Mr_Superjaffa 601";
				break;
			case "637ad734e9e55da7c285b4eabc18edc8":
				out = "[107th] Petey 369";
				break;
			case "yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy":
				out = "Never Forget";
				break;
			case "zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz":
				out = "Legacy Mod";
				break;
			case "gggggggggggggggggggggggggggggggg":
				out = "Host";
				break;
			case "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx":
				out = "Autoban";
				break;
			default:
				out = false;
				break;
		}
		return out;
	};

	displayFormatedList = () => {
		let banNum = 0;
		let tabNorm = [];
		let tabSpecial = [];

		for (const data of this.state.rawTab) {
			banNum++;
			if (data.ucid !== data.name) {
				tabNorm.push(
					<tr className="singleBan" key={data.ucid}>
						<td className="ucid">{data.ucid}</td>
						<td className="name">{data.name}</td>
						<td className="bannedBy">{this.truncate(data.bannedBy.name ? data.bannedBy.name : "autoban")}</td>
						<td className="timeDate">{formatDate(data.time * 1000)}</td>
					</tr>
				)
			} else {
				tabSpecial.push(
					<tr className="singleBan" key={data.ucid}>
						<td className="ucid">{data.ucid}</td>
						<td className="bannedBy">{this.truncate(data.bannedBy.name ? data.bannedBy.name : "autoban")}</td>
						<td className="timeDate">{formatDate(data.time * 1000)}</td>
					</tr>
				)
			}
		}

		this.setState({
			lookingAtBan: true,
			currentFileContent: tabNorm,
			specialBan: tabSpecial,
			banNumber: banNum
		});
	};

	render() {
		let out = undefined;

		let displayFormated = (
			<div>
				<div className="category">
					<h2>Regular old Ban</h2>
					<table>
						<tbody>
						<tr>
							<th>UCID</th>
							<th>Name</th>
							<th>Banned By</th>
							<th>Date</th>
						</tr>
						{this.state.currentFileContent}
						</tbody>
					</table>
				</div>
				<div className="category">
					<h2>Special Ban</h2>
					<table>
						<tbody>
						<tr>
							<th>UCID</th>
							<th>Banned By</th>
							<th>Date</th>
						</tr>
						{this.state.specialBan}
						</tbody>
					</table>
				</div>
			</div>
		);

		let displayRaw = (
			<div className="rawDisplay">
				<pre>{this.state.currentFileContentRaw ? this.state.currentFileContentRaw : "Not looking at a file"}</pre>
			</div>
		);

		let statsTab = (
			<div className="stats category">
				<h2>Stats</h2>
				<div className="innerStats">
					<div>
						<h3>Ban per Moderator</h3>
						{this.state.donutMod ? <ChartDonut data={this.state.donutMod} colors={generateColors(this.state.donutMod.length)}/> : null}
					</div>
					<div>
						<h3>Ban Time Line</h3>
						{this.state.areaChartData ? <ChartAreaChart data={this.state.areaChartData} labels={this.state.areaChartLabels}/> : null}
					</div>
					<div>
						<h3>Ban Per Hour</h3>
						{this.state.banPerHour ?
							<ChartBar data={this.state.banPerHour} labels={["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]}/> : null}
					</div>
				</div>
			</div>
		);

		let loading = (
			<div className="category">
				<h1>Loading ...</h1>
			</div>
		);

		let error = (
			<div className="category">
				<h1>There's has been an error</h1>
			</div>
		);

		if (this.state.displayRaw) {
			out = displayRaw
		} else {
			out = displayFormated
		}

		if (this.state.errorLoading) {
			out = error;
		}

		return (
			<div className="BanList card">
				{this.state.stats ? statsTab : null}
				<div className="topStuff">
					<span>Ban Amount: {this.state.banNumber}</span>
					<label>Display Raw File <input type="checkbox" onChange={this.onDisplayRawChange}/></label>
					<button onClick={this.sortListByTime}>Sort By Time</button>
					<button onClick={this.sortListByMod}>Sort By Mod</button>
					<button onClick={this.sortListByUCID}>Sort By UCID</button>
				</div>
				{this.state.isLoading ? loading : out}
			</div>
		);
	}
}