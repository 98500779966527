import React, {Component} from 'react';
import axios from "axios";
import {Redirect} from "react-router-dom";

export default class Auth extends Component {

	state = {
		redirectHome: false,
		redirectApplication: false,
		squadronMember: false,
		discordUserInfo: undefined,
		isNotInTTI: false
	};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	componentDidMount() {
		let url_string = window.location.href;
		let url = new URL(url_string);
		let code = url.searchParams.get("code");

		// const server = axios.create({
		// 	baseURL: 'https://reorg.throughtheinferno.com:8081/',
		// 	timeout: 180000
		// });

		const param = {
			code: code
		};

		this.server.post('getDiscordUser', param)
			.then((response) => {
				if(response.data === false){
					this.setState({
						isNotInTTI: true
					});
				}else{
					sessionStorage.apiData = JSON.stringify(response.data);

					this.setState({
						discordUserInfo: response.data,
						redirectHome: true
					});
				}

			})
			.catch(err => {
				console.log(err);
			});

	}

	render() {

		if (this.state.redirectHome) {
			return <Redirect to='/home'/>;
		}

		if(this.state.isNotInTTI){
			return (<div className="Auth">
				<div className="header">
					<img src="/media/107th.svg" alt=""/>
					<h1>Squadron Area</h1>
				</div>

				<div>
					<h2>You must join the Through The Inferno Discord</h2>
				</div>
			</div>)
		}

		return (
			<div className="Auth">
				<div className="header">
					<img src="/media/107th.svg" alt=""/>
					<h1>Squadron Area</h1>
				</div>

				<div>
					<h2>Looks like connecting didn't go has planned, give it a minute it might work</h2>
				</div>
			</div>
		);
	}
}