import React, {Component} from 'react';

export default class Countdown extends Component {

	state = {
		dayLeft: undefined,
		hourLeft: undefined,
		minuteLeft: undefined,
		secondLeft: undefined,
		eventHasBegun: false
	};

	timer = undefined;

	constructor(props) {
		super(props);
		this.timerFunc();
	}

	timerFunc = () => {
		this.timer = setInterval(this.getTimeLeft, 1000);
	};

	getTimeLeft = () => {
		let countDownDate = new Date(this.props.dateTime).getTime();

		let now = new Date().getTime();
		let distance = countDownDate - now;

		let days = Math.floor(distance / (1000 * 60 * 60 * 24));
		let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		let seconds = Math.floor((distance % (1000 * 60)) / 1000);

		if (days < 10) days = "0" + days;
		if (hours < 10) hours = "0" + hours;
		if (minutes < 10) minutes = "0" + minutes;
		if (seconds < 10) seconds = "0" + seconds;

		this.setState({
			dayLeft: days,
			hourLeft: hours,
			minuteLeft: minutes,
			secondLeft: seconds,
		});

		if (distance < 0) {
			this.setState({
				dayLeft: "00",
				hourLeft: "00",
				minuteLeft: "00",
				secondLeft: "00",
				eventHasBegun: true
			});
			clearInterval(this.timer);
		}

	};

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	render() {

		let count = (
			<div className="countdown">
				<div className="component">
					<div className="number">{this.state.dayLeft}</div>
					<div className="text">Days</div>
				</div>
				<div className="component">
					<div className="number">{this.state.hourLeft}</div>
					<div className="text">Hours</div>
				</div>
				<div className="component">
					<div className="number">{this.state.minuteLeft}</div>
					<div className="text">Minutes</div>
				</div>
				<div className="component">
					<div className="number">{this.state.secondLeft}</div>
					<div className="text">Seconds</div>
				</div>
			</div>);

		let timerOver = (<div className="eventBegun">Event Has already begun</div>);

		return this.state.eventHasBegun ? timerOver : count;

	};

}