import React, {Component} from 'react';
import axios from "axios/index";
import {Redirect} from "react-router-dom";
import {allPlaneList} from "../comon";

export default class EventCreator extends Component {

	state = {
		eventName: "Untitled Event",
		eventDateTime: new Date().toISOString(),
		eventDateTimeNotFormated: new Date(),
		eventPlane: allPlaneList,
		eventDescription: "No description",
		eventMap: undefined,
		eventImgBool: false,
		eventVisibility: "public",
		eventImgSrc: "2018/02/wallhaven-618621.jpg",
		redirect: false,
		participant: [],
		allPlaneAvailable: true
	};

	eventPlane = allPlaneList;

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	constructor(props){
		super(props);
	}

	componentDidMount(){
		//console.log(this.props.location.state);
		//console.log(this.props.location);
	}

	onNameChange = changeEvent => {
		if (changeEvent.target.value === "") {
			this.setState({
				eventName: "Untitled Event"
			});
		} else {
			this.setState({
				eventName: changeEvent.target.value
			});
		}
	};

	onTimeDateChange = changeEvent => {
		let time = new Date(changeEvent.target.value);
		let timeISO = time.toISOString();

		this.setState({
			eventDateTime: timeISO,
			eventDateTimeNotFormated: changeEvent.target.value
		});
	};

	onDescriptionChange = changeEvent => {
		if (changeEvent.target.value === "") {
			this.setState({
				eventDescription: "No description"
			});
		} else {
			this.setState({
				eventDescription: changeEvent.target.value
			});
		}
	};

	onMapChange = changeEvent => {
		this.setState({
			eventMap: changeEvent.target.value
		});
	};

	onVisibilityChange = changeEvent =>{
		this.setState({
			eventVisibility: changeEvent.target.value
		});
	};

	onRoleChange = changeEvent => {
		this.eventPlane[changeEvent.target.value] = changeEvent.target.checked;

		this.setState({
			eventPlane: this.eventPlane,
			allPlaneAvailable: false
		});
	};

	onImgSrcChange = changeEvent => {
		this.setState({
			eventImgSrc: changeEvent.target.value
		});

		if(changeEvent.target.value !== ""){
			this.setState({
				eventImgBool: true
			});
		}else{
			this.setState({
				eventImgBool: false
			});
		}
	};

	onCheckAll = changeEvent => {
		for(let single in this.eventPlane){
			this.eventPlane[single] = !this.eventPlane[single];
		}

		this.setState({
			eventPlane: this.eventPlane
		});
	};

	validateInputs = () => {

		if(this.state.eventName === "Untitled Event" || this.state.eventName === ""){
			return {
				error: true,
				message: "Event is Untitled"
			};
		}

		if(this.state.eventDescription === "No description" || this.state.eventDescription === ""){
			return {
				error: true,
				message: "Description is empty"
			};
		}

		return {
			error: false,
			message: undefined
		};
	};

	sendForm = event => {

		let error = this.validateInputs();
		if(error.error) return;

		let alias = this.state;
		let eventData = {

		};

		this.server.post("/newEvent", this.state, this.requestAuth)
			.then((response) => {
				if(response.status === 200){
					this.setState({
						redirect: true
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	render() {

		if (this.state.redirect) {
			return <Redirect to='/home/event'/>;
		}
		let errorMessage = undefined;
		let error = this.validateInputs();
		if(error.error){
			errorMessage = (<div className="errorMessage">Error: {error.message}</div>);
		}


		return (
			<section className="EventCreator">
				<h1>Event Creator</h1>
				<div className="topInfo">
					<input type="text" placeholder="Name" onChange={this.onNameChange}/>
					<input type="datetime-local" value={this.state.eventDateTimeNotFormated} onChange={this.onTimeDateChange}/>
				</div>

				<div className="detail">
					<h3>Description</h3>
					<textarea placeholder="Description or whatever the fuck you want" onChange={this.onDescriptionChange}/>
				</div>

				<div className="imgSrc">
					<h3>Image <span>Every link is preceded with: <a href="https://throughtheinferno.com/wp-content/uploads/" target="_blank" rel="noopener noreferrer">https://throughtheinferno.com/wp-content/uploads/</a></span></h3>
					<input type="text" placeholder="2018/06/Capture2-min.png" onChange={this.onImgSrcChange}/>

				</div>

				<div className="mapChoose">
					<h3>Map</h3>
					<div>
						<label><input name="map" type="radio" value="ccs" onChange={this.onMapChange}/>Caucasus</label>
						<label><input name="map" type="radio" value="pg" onChange={this.onMapChange}/>Persian Gulf</label>
						<label><input name="map" type="radio" value="nttr" onChange={this.onMapChange}/>Nevada</label>
						<label><input name="map" type="radio" value="normandy" onChange={this.onMapChange}/>Normandy</label>
					</div>
				</div>

				<div className="planeChoose">
					<h3>Available Roles <span>If none are checked it is assumed that all are available</span></h3>


					<div className="gridContainer">
						<div className="top">
							<button onClick={this.onCheckAll}>Toggle All Roles</button>
						</div>

						<div className="airplaneType">
							<p>Modern Airplane</p>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.a10c} type="checkbox" value="a10c"/>A-10C</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.m2000} type="checkbox" value="m2000"/>M-2000C</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.av8b} type="checkbox" value="av8b"/>AV-8B N/A</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.f18} type="checkbox" value="f18"/>F/A-18C</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.f16} type="checkbox" value="f16"/>F-16C</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.jf17} type="checkbox" value="jf17"/>JF-17</label>
						</div>
						<div className="airplaneType">
							<p>Arcade Airplane</p>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.mig29a} type="checkbox" value="mig29a"/>MiG-29A</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.mig29s} type="checkbox" value="mig29s"/>MiG-29S</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.mig29g} type="checkbox" value="mig29g"/>MiG-29G</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.su33} type="checkbox" value="su33"/>Su-33</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.su27} type="checkbox" value="su27"/>Su-27</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.su25} type="checkbox" value="su25"/>Su-25</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.su25T} type="checkbox" value="su25T"/>Su-25T</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.j11} type="checkbox" value="j11"/>J-11</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.a10a} type="checkbox" value="a10a"/>A-10A</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.f15c} type="checkbox" value="f15c"/>F-15C</label>
						</div>
						<div className="airplaneType">
							<p>Helicopter</p>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.ka50} type="checkbox" value="ka50"/>Ka-50</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.uh1h} type="checkbox" value="uh1h"/>UH-1H</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.mi8} type="checkbox" value="mi8"/>MI-8MTV2</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.gazelleM} type="checkbox" value="gazelleM"/>SA342M</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.gazelleL} type="checkbox" value="gazelleL"/>SA342L</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.gazelleMistral} type="checkbox" value="gazelleMistral"/>SA342 Mistral</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.gazelleMinigun} type="checkbox" value="gazelleMinigun"/>SA342 Minigun</label>
						</div>
						<div className="airplaneType">
							<p>Older airplane</p>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.f14a} type="checkbox" value="f14a"/>F-14A</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.f14b} type="checkbox" value="f14b"/>F-14B</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.f5e} type="checkbox" value="f5e"/>F-5E</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.ajs37} type="checkbox" value="ajs37"/>AJS-37</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.f86f} type="checkbox" value="f86f"/>F-86F</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.mig21} type="checkbox" value="mig21"/>MiG-21bis</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.mig15} type="checkbox" value="mig15"/>MiG-15bis</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.mig19} type="checkbox" value="mig19"/>MiG-19P</label>
						</div>
						<div className="airplaneType">
							<p>WWII Airplane</p>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.p51} type="checkbox" value="p51"/>P-51D</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.spitfirelfmkix} type="checkbox" value="spitfirelfmkix"/>Spitfire LF Mk IX</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.spitfirelfmkixCW} type="checkbox" value="spitfirelfmkixCW"/>Spitfire LF Mk IX CW</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.fw190d9} type="checkbox" value="fw190d9"/>Fw 190 D-9</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.bf109k4} type="checkbox" value="bf109k4"/>Bf 109 K-4</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.fw190a8} type="checkbox" value="fw190a8"/>Fw 190 A-8</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.i16} type="checkbox" value="i16"/>I-16</label>
						</div>
						<div className="airplaneType">
							<p>Almost useless</p>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.l39za} type="checkbox" value="l39za"/>L-39ZA</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.l39c} type="checkbox" value="l39c"/>L-39C</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.c101cc} type="checkbox" value="c101cc"/>C-101CC</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.c101eb} type="checkbox" value="c101eb"/>C-101EB</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.tf51} type="checkbox" value="tf51"/>TF-51D</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.yak52} type="checkbox" value="yak52"/>Yak-52</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.ce2} type="checkbox" value="ce2"/>Christen Eagle II</label>
						</div>
						<div className="controller">
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.atc} type="checkbox" value="atc"/>ATC</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.awacs} type="checkbox" value="awacs"/>AWACS</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.lso} type="checkbox" value="lso"/>LSO</label>
							<label><input onChange={this.onRoleChange} checked={this.state.eventPlane.fac} type="checkbox" value="fac"/>FAC</label>
						</div>
					</div>
				</div>

				<div className="visibilityChoose">
					<h3>Visibility</h3>
					<div>
						<label><input name="visibility" type="radio" value="public" onChange={this.onVisibilityChange}/>Public</label>
						<label><input name="visibility" type="radio" value="squadron" onChange={this.onVisibilityChange}/>107th Only</label>
					</div>
				</div>

				{errorMessage ? errorMessage : null}
				<input type="submit" value="Create Event" className={error.error ? "grayedOut" : null} onClick={this.sendForm}/>

			</section>
		);
	}
}