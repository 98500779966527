import React, {Component} from 'react';
import Chart from 'chart.js';

export default class ChartBar extends React.Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
	}

	componentDidUpdate() {
		//this.myChart.data.labels = this.props.data.map(d => d.label);
		//this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
		this.myChart.update();
	}

	componentDidMount() {
		this.myChart = new Chart(this.canvasRef.current, {
			type: 'bar',
			options: {
				maintainAspectRatio: false,
				legend: {
					position: "right",
					align: "left"
				}
			},
			data: {
				datasets: this.props.data,
				labels: this.props.labels,
			}
		});

		this.myChart.update();
	}

	render() {
		return(
			<div className="ChartBar">
				<canvas ref={this.canvasRef} />
			</div>
		);
	}
}
