import React, {Component} from 'react';
import {Link} from "react-router-dom";
import SingleEvent from "./SingleEvent";
import axios from "axios/index";

export default class Event extends Component {

	state = {
		userCanCreateEvent: true,
		events: null
	};

	server = axios.create({
		baseURL: "https://reorg.throughtheinferno.com:8081/",
		timeout: 180000
	});

	api = JSON.parse(sessionStorage.apiData);

	requestAuth = {
		headers: {
			userid: this.api.userInfo.id,
			token: this.api.oauth.access_token
		}
	};

	constructor(props) {
		super(props);
		this.getEvent();

		this.getEvent = this.getEvent.bind(this);

	}

	checkCanCreateEvent() {
		if(this.props.eventCoord === true){
			return <section className="eventHeader"><Link to={"/home/event/eventCreator"}>Create new event</Link></section>;
		}
	}

	getEvent = () => {
		this.server.post("/getEvent", null, this.requestAuth)
			.then((response) => {
				this.setState({
					events: response.data
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	displayEvents(){
		let userId = this.props.discordId;
		let out = [];
		let eventCoord = false;

		if(this.props.eventCoord === true){
			eventCoord = true;
		}


		if(this.state.events !== null){
			if(this.state.events.length !== 0){
				for(let e of this.state.events){
					if(e.content !== undefined){
						out.push(<SingleEvent name={e.content.eventName} key={e.id} eventId={e.id} canCreateEvent={eventCoord} playerId={userId} event={e.content} refresh={this.getEvent} />);
					}
				}
				return out;
			}else{
				return <p>No Events</p>;
			}

		}else{
			return <p>No Events</p>;
		}

	}

	render() {

		return (
			<section className="Event">
				{this.checkCanCreateEvent()}
				{this.displayEvents()}
			</section>
		);
	}
}